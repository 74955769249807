import logo from './logo.svg';
import './App.css';
import HashLoader from "react-spinners/HashLoader";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo">
        <HashLoader 
          color={'#6c00b4'}
          loading={true} 
          size={150} 
          />
        </div>
        <div className="wip-text">
          <p>
            Androgyn hasn't joined the Metaverse yet...
          </p>
          <p>
            Website's coming soon!
          </p>
          
          <a
            className="App-link"
            href="https://discord.gg/jbRM2STZAP"
            target="_blank"
            rel="noopener noreferrer"
          >
          <img
                      src="./img/misc/discord.png"
                      className="img-fluid discord-logo"
                      alt="#"
              />
          </a>

          </div>
      </header>
    </div>
  );
}

export default App;
